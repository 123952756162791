import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./CheckoutForm";

import _ from "lodash";
import Payments from "../../../service/common/PaymentApi";

function Striperes(props) {
  let stripePromise = "";
  const [clientSecret, setClientSecret] = useState("");
  const [publicKey, setPublicKey] = useState(props?.data?.public_key);

  const service = new Payments();

  useEffect(() => {
    service.createPayment(props.product, "Stripe").then((res) => {
      if (res.status) {
        setClientSecret(res.data?.paymentIntent?.client_secret);
      }
    });
  }, []);

  if (clientSecret != "") {
    stripePromise = loadStripe(publicKey);
  }

  const options = {
    clientSecret: clientSecret,
  };
  return (
    <div className="app" style={{ minWidth: "400px" }}>
      {stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          {console.log("===========================")}
          <Checkout handleSubmitFinish={props.submit} />
        </Elements>
      )}
    </div>
  );
}

export default Striperes;
