// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://52.6.220.88/admin/backoffice/api/";
// const IMG_URL = "https://52.6.220.88/admin/uploads/images/";
// const BASE = "https://52.6.220.88/admin";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='79ec40ec-86bf-4ea2-92bf-e8e52ff33907'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }


import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://wishgranted.me/admin/backoffice/api/";
const IMG_URL = "https://wishgranted.me/admin/uploads/images/";
const BASE = "https://wishgranted.me/admin";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY ='79ec40ec-86bf-4ea2-92bf-e8e52ff33907'
console.log(localStorage);
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo2.infinitemlmdemo.com/marlon/backoffice/api/";
// const IMG_URL = "https://demo2.infinitemlmdemo.com/marlon/uploads/images/";
// const BASE = "https://demo2.infinitemlmdemo.com/marlon";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '79ec40ec-86bf-4ea2-92bf-e8e52ff33907';
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.5.64/WC/marlon/backoffice/api/";
// const IMG_URL = "http://192.168.5.64/WC/marlon/uploads/images/";
// const BASE = "http://192.168.5.64/WC/marlon";
// // const API_KEY ='2aa4e7c5-f13e-4344-a7ea-f5c4efe04f8b';
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '79ec40ec-86bf-4ea2-92bf-e8e52ff33907';
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.9.31/WC/marlon/backoffice/api/";
// const IMG_URL = "http://192.168.9.31/WC/marlon/uploads/images/";
// const BASE = "http://192.168.9.31/WC/marlon";
// // const API_KEY ='2aa4e7c5-f13e-4344-a7ea-f5c4efe04f8b';
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '79ec40ec-86bf-4ea2-92bf-e8e52ff33907';
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }



// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.9.8/WC/marlu/backoffice/api/";
// const IMG_URL = "http://192.168.9.8/WC/marlu/uploads/images/";
// const BASE = "http://192.168.9.8/WC/marlu";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '79ec40ec-86bf-4ea2-92bf-e8e52ff33907';
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }