import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import CartContent from "../../components/shopping/CartContent";
import Spinner from "../../shared/Spinner";
import shoppingService from "../../../service/shopping";
import { withRouter } from "react-router-dom";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory } from "react-router";

function ShoppingCart(props) {
  let service = new shoppingService();
  let history = useHistory();

  const [state, setState] = useState({
    data: [],
    loader: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    cart: [],
    validity :null,
  });

  useEffect(() => {
    const location = props.location;
    if (location) {
      const { t } = props;
      let varient = "danger";
      let message = "";
      if (location.success) {
        varient = "success";
        message = location.update ? "quantityUpdated" : "addToCartSuccess";
      }
      // setState({
      //     alert : {
      //         show : true,
      //         varient : varient,
      //         message : t(`validation.${message}`,{name:state.productName})
      //     }
      // })
      // setState(prevState => ({
      //     ...prevState,
      //     alert: {
      //         show: true,
      //         varient: varient,
      //         message: t(`validation.${message}`, { name: location.productName })
      //     }
      // }))
    }
    service.getRepurchasePackageList().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          data: res.data.products,
          loader: false,
          validity:res.data.validity
        }));
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
    getCart();
  }, []);
  const getCart = () => {
    service.getCart().then((res) => {
      if (res.status) {
        let cartItems = Object.values(res.data);
        setState((prevState) => ({
          ...prevState,
          cart: cartItems,
        }));
      } else {
        history.push("/logout");
      }
    });
  };
  const repurchaseReport = () => {
    history.push("/repurchaseReport");
  };

  const ButtonGroup = [
    {
      name: "repurchaseReport",
      click: repurchaseReport,
    },
  ];

  //close alert
  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        varient: "",
        message: "",
      },
    }));
  };

  //add to cart
  const addToCart = (id, name) => {
    const formData = {
      product_id: id,
      product_qty: 1,
    };

    service.addToCart(formData).then((res) => {
      if (res.status) {
        getCart();
        setState((prevState) => ({
          ...prevState,
          alert: {
            show: true,
            varient: "success",
            message: t(`validation.addToCartSuccess`, { name: name }),
          },
        }));
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1050) {
          setState((prevState) => ({
            ...prevState,
            alert: {
              show: true,
              varient: "danger",
              message: t(`validation.invalidProduct`),
            },
          }));
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    });
  };

  const { t } = props;
  if (state.loader) {
    return <Spinner />;
  }

  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.shopping")}
        </title>
      </Helmet>
      <PageTitle
        title="shopping"
        buttonOn={true}
        AddonIcon={true}
        icon="fa fa-bar-chart"
        group={ButtonGroup}
      />
      <CartContent {...state} closeAlert={closeAlert} addToCart={addToCart} />
    </div>
  );
}
export default withTranslation()(withRouter(ShoppingCart));
