import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import style from "./tools.module.scss";
import DownloadService from "../../../service/tools/download";
import { useHistory } from "react-router-dom";
import { BASE } from "../../../configuration/apiconfig";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';

import ReactPaginate from "react-paginate";
import styles from "../../components/tree//tree.module.scss";

const EbookCom = () => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const history = useHistory();
  const [state, setState] = useState({
    value : '',
    offset : 0
  });
  const [loading, setLoading] = useState(false);
  const {t}  = useTranslation();
  useEffect(() => {
    onsubmit()
  }, [history.action,state.offset]);

const onsubmit = ()=>{

  const service = new DownloadService();
    setLoading(true);
    service.getEbook(state.value,state.offset,10).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          category : res.data.categories,
          document : res.data.documen_data,
          count : res.data.count,
        }));
        
        setLoading(false);
      } else {
        setLoading(false);
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1057) {
          history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
}


  const download = (e) => {
    var fn = e.match(/([^/])+/g);
    fn = fn[fn.length - 1];
    fetch(e).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fn;
        a.click();
      });
    });
  };

  const changehandler = (e,key) =>{

    console.log(e,key)
 
    const  { name , value} = e.target;
console.log("name",name,"value",value)



setState(prev=>({
...prev,
value : value
}))
 }


 const submitHandler = (mobile) =>{

      
  onsubmit(state.value)
// loader.EarningsTable.search = true


// paginationchange(
//   ContentData.inPage,
//   ContentData.perPage,
  
//   )
}

const handlePageClick = (data) => {
  let selected = data.selected;
  let offset = Math.ceil(selected * 10);
  let showNext = true
  if(offset>0){
      showNext = Math.floor(state.count/offset)===1?false:true
  }
  setState(prev=>({
      ...prev,
      offset : offset,
      showNext  :showNext
  }));
 // onsubmit(state.value,offset)
};


const docIcon = (id) => {
  if (id === "1") {
    return (
      <img
        src={BASE + "/backoffice/public_html/images/document/document.svg"}
        alt=""
      />
    );
  } else if (id === "2") {
    return (
      <img
        src={BASE + "/backoffice/public_html/images/document/image.svg"}
        alt=""
      />
    );
  } else if (id === "3") {
    return (
      <img
        src={BASE + "/backoffice/public_html/images/document/mov.svg"}
        alt=""
      />
    );
  }
};
  return loading ? (
    <Spinner />
  ) : (
    
    <div className={style.downloadConatiner}>
      <div className={style.filterContainer}>
       <Form.Control 
                        as="select"
                       // disabled={props.status||props.data.disabled}
                        className={`${style.inputField}`}
                        name={"category"}
                        onChange={e=>changehandler(e,state?.category?.cat_id)}
                        // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
                        value={state?.value ?? ''}
                        >
                            <option  value=''>{"ALL"}</option>
                        {
                            state.category?.map((option,index)=>{
                                return <option key={index} value={option.cat_id}>{option.cat_name}</option>
                            })
                        }
                        
                    </Form.Control>
                    <div className={style.filterButton}>
                    <Button variant="info" className={style.padding5} onClick={submitHandler}>

                   {/* { loader.EarningsTable.search ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''
                   } */}

                        {t('Button.search')}
                    </Button> 
                    </div>
                    {/* <Button variant="secondary" className={style.padding5} onClick={resetHandler}> */}

{/* { loader.EarningsTable.search ?
         <i className="fa fa-refresh fa-spin"
             style={{ marginRight: "5px" }}
         /> : ''
} */}

     {/* {t('Button.reset')}
 </Button>  */}
                    </div>
      <div className={style.userDowmloadSection}>
        {state?.document?.map((items, index) => (
          <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_content}`
              : `${style.content}`
          }`} key={index}>
            <div>{docIcon("1")}</div>
            <div className={style.snddiv}>
              <div>{items.tittle} </div>
              <div>{items.date_added}</div>
              <div>{items.description}</div>
            </div>
            <div>
              <div className={style.placeright}>
                <Button variant="success">
                  {/* <a download="foo.txt" target="_blank" href="http://example.org/assets/avatar.png">download</a> */}

                  <a
                    href={items.doc_name}
                    target="_blank"
                    className={style.linkcoloor}
                    download
                    onClick={(e) => download(items.doc_name)}
                  >
                    <i className="fa fa-download" />
                    download
                  </a>
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel={t("<<")}
        nextLabel={t(">>")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(state.count / 10)}
        marginPagesDisplayed={5}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={styles.paginationContainer}
        activeClassName={styles.active}
        previousLinkClassName={state.offset === 0 ? "d-none" : style.Link}
        nextLinkClassName={!state.showNext ? "d-none" : style.Link}
      />
    </div>
  );
};

export default EbookCom;
