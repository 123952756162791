import React from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5`
          : `${style.app} pt-5`
      }`}
    >
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <Brand />
        <Language classname="mb-4 w-100" />
        {children}
      </Container>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_lg_login_btn_btm_1}`
            : `${style.lg_login_btn_btm_1}`
        }`}
      >
        2024 © Company Name -{"wishgranted"}
      </div>
    </div>
  );
}

export default AuthLayout;
