import React from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import { DownloadContent } from '../../components/tools';
import { EbookCom } from '../../components/tools';

function Ebook(props) {
    const { t } = props;
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.E-book')}</title>
            </Helmet>
            <PageTitle
                title="E-book"
                buttonOn={false}
            />
            <EbookCom />
        </div>
    );



}
export default withTranslation()(withRouter(Ebook))