import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import PaymentMethods from "../common/PaymentMethods";
import style from "./registration.module.scss";
import { Paneltilte } from "./";

function Payment(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);
  let amount =
    parseInt(props.productAmount) + parseInt(props.data.registrationFee);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_paymentMethod}`
          : `${style.paymentMethod}`
      }`}
    >
      <Paneltilte tilte="paymentType" />
      <div className={style.totalAmount}>
        <h4>
          {t("Common.totalAmount")} : {currency.currentCurr}{" "}
          {(amount * currency.value).toFixed(currency.precision)}
        </h4>
      </div>
      <Form
        onSubmit={(e) => {
          props.submit(e, "payment");
        }}
      >
        <PaymentMethods
          methods={props.data.fields}
          //   selectedTab={1}
          // prevButtonClick={props.prevButtonClick}
          methodChange={props.paymentMethodChange}
          // username={props.userName}
          // product={props.product}
          amount={amount}
          payment_type={props.payment_type}
          {...props}
        />
      </Form>
    </div>
  );
}

export default Payment;
